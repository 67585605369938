<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 17:55:09
 * @LastEditTime : 2022-07-12 16:53:00
 * @LastEditors  : BigBigger
-->
<template>
<div class="app-page-1">
  <div class="course-detail" v-if="detail">
    <!-- <div class="banner-box">
      <img :src="detail.courseCover">
    </div> -->
    <div class="detail">
      <div class="detail-card">
        <CourseGroupDetailCard :detail="detail" @confirm="toSingup" />
      </div>
      <ThroughPay v-if="detail.throughPay === 1" v-model="throughPay" :detail="detail" />
      <div class="classdetail-card">
        <ClassDetailCard :detail="detail" :classList="classList" v-if="detail.whetheHaveClassManager === 1" @getClass="getClass" />
      </div>
      <div class="detail-flex">
        <div class="class-intro">
          <van-sticky>
            <Tabs :actived="activedTab" :list="tabList" @onChange="tabsOnChange"></Tabs>
          </van-sticky>
          <CourseIntroduct :detail="detail" v-if="activedTab === 0" />
          <CourseList :detail="detail" v-if="activedTab === 1" />
          <TeacherIntroduct :detail="detail" v-if="activedTab === 2" />
          <MatchingCourse :detail="detail" v-if="activedTab === 4" />
        </div>
      </div>
    </div>
    <div class="sign-box">
      <!-- <div class="weixin" @click="showWexin">
        <img src="@/assets/images/icon/wechatpay.png">
        <span>微信</span>
      </div> -->
      <template v-if="detail.whetherReation === 2">
        <p class="sign-btn" @click="confirm(btnStateComputed.type)" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</p>
        <p class="sign-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</p>
      </template>
      <template v-if="detail.whetherReation !== 2">
        <p class="sign-btn" @click="ptclassTip">配套课程</p>
      </template>
    </div> 

    <van-popup position="bottom" v-model="showClassList" style="height:60%">
      <div class="wrapper">
        <ClassList  @classOnClose="classOnClose" :detail="detail" @getClassList="getClassList" />   
      </div>
    </van-popup>

    <van-overlay :show="showTeacherDetail" >
      <div class="wrapper">
        <BuyConsultative  @onClose="infoOnClose" :detail="detail" />   
      </div>
    </van-overlay>
    
  </div>

</div>
  
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
import { getCourseGroupDetail, checkBeforeCreate, getLessonGroupTips } from "@/api/api"
import Breadcrumb from '@/components/public/Breadcrumb'
import CourseGroupDetailCard from "@/components/courseGroup/CourseGroupDetailCard"
import IntCard from '@/components/courseGroup/IntCard'
import Tabs from '@/components/courseGroup/Tabs'
import CourseIntroduct from '@/components/courseGroup/CourseIntroduct'
import CourseList from '@/components/courseGroup/CourseList'
import TeacherIntroduct from '@/components/courseGroup/TeacherIntroduct'
import MatchingCourse from '@/components/courseGroup/MatchingCourse'
import BuyConsultative from '@/components/courseGroup/BuyConsultative'
import ClassDetailCard from '@/components/courseGroup/ClassDetailCard'
import ThroughPay from '@/components/courseGroup/ThroughPay'
import ClassList from '@/components/courseGroup/ClassList'
import { checkOrderStatus } from '@/views/common/check';
export default {
  name: "Detail",
//   async asyncData({params, $axios}){
//     let activedTab = 0;
//     let detail = await $axios.post(getCourseGroupDetail, null, {params:{id: params.id}})
//     if(+detail.returnCode !== 10001) {
//       detail.data = {}
//     }
//     if(detail.data.introductionImg) {
//       detail.data.introductionImgList = detail.data.introductionImg.split(",")
//     } else {
//       detail.data.introductionImgList = []
//     }
//     const tabList = [
//       {label: '课程介绍', key: 0, state: true},
//       {label: '课程安排', key: 1, state: detail.data.hasCurriculum === 1},
//       {label: '老师介绍', key: 2, state: detail.data.teachers && detail.data.teachers.length > 0},
//       {label: '课程评价', key: 3, state: false},
//       {label: '配套网课', key: 4, state: detail.data.relationCourseGroupId && detail.data.relationCourseGroupId.length > 0},
//     ]

//     for(let item in tabList) {
//       if(item.state) {
//         activedTab = item.key
//         return 
//       }
//     }
   
//     return {
//       detail: detail.data,
//       activedTab: activedTab,
//       tabList: tabList,
//       breadcrumbList: [
//         {name: '全部课程', url:'/course'},
//         {name: detail.data.categoryName, url:''},
//         {name: detail.data.courseGroupName}
//       ],
//       showTeacherDetail:false
//     }
//   },
  data() {
    return {
      courseId: null,
      activedTab: 0,
      detail:null,
      orderId:null,
      tabList: [],
      breadcrumbList: [],
      showTeacherDetail: false,
      showClassList: false ,
      classList: null,
      throughPay: 0,
    }
  },
  mounted() {
    checkOrderStatus(this)
    this.$store.dispatch('clearOrderInfo')
    this.courseId = this.$route.params.id
    if(this.detail) {
      this.breadcrumbList[1].name = this.getClassType(this.detail.categoryName)
      this.breadcrumbList[1].url = `/course?categoryName=${this.getClassType(this.detail.categoryName)}`
    }
    this.getCourseGroupDetail()
    this.getLessonGroupTips()

    // 没看懂什么意思，导致死循环
    //返回刷新
    // window.onload = function(){
    //   var url = window.location.href;
    //   var ps = url.split("#");
    //   try{
    //     if(ps[1] != 1){
    //       url += "#1";
    //     }else{
    //       window.location = ps[0];
    //     }
    //   }catch(ex){
    //     url += "#1";
    //   }
    //   window.location.replace(url);
    // }           
},
  components: {
    Breadcrumb,
    CourseGroupDetailCard,
    IntCard,
    Tabs,
    CourseIntroduct,
    CourseList,
    TeacherIntroduct,
    MatchingCourse,
    BuyConsultative,
    ClassDetailCard,
    ClassList,
    ThroughPay
  },
  methods: { 
    ptclassTip() {
      this.$toast('该课程无法购买')
    },
    async getCourseGroupDetail () {
      let response = await getCourseGroupDetail({id: this.courseId})
      if(+response.returnCode !== 10001) {
        return false
      }
      let detail = response.data
      this.detail = detail
      this.tabList = [
        {label: '课程介绍', key: 0, state: true},
        {label: '课程安排', key: 1, state: detail.hasCurriculum === 1},
        {label: '老师介绍', key: 2, state: detail.teachers && detail.teachers.length > 0},
        {label: '课程评价', key: 3, state: false},
        {label: '配套网课', key: 4, state: detail.relationCourseGroupId && detail.relationCourseGroupId.length > 0},
      ]
      document.title = this.detail.courseGroupName
    },
    getClassType(str = "") {
      return str.split(' | ')[2]
    },
    tabsOnChange(val) {
      this.activedTab = val.key
    },
    showWexin(){
      this.showTeacherDetail = true
    },
    async toSingup(val) {
      // 关联职位列表，跳转报名步骤 非关联的，就直接跳支付
      let canBuy = await this.checkBeforeCreate()
      if(!canBuy) {
        return false
      }
      if(+val === 1) {
        this.$router.push({path:`/course/signup`})
      } else {
        // this.$router.push({path:`/course/signup`,query:{
        //   step: 2,
        // }})
        this.$router.push({path:`/course/signup`})
      }
      // window.location.href = `/course/signup`
    },
    async checkBeforeCreate(val) {
      if(this.detail.whetheHaveClassManager === 1){
        if(!this.classList){
          this.$toast('请选择班级')
          return false
        }
      }
      if(this.detail.throughPay === 1){
        if(this.throughPay === undefined){
          this.$toast('请选择付费模式')
          return false
        }
      }
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      } else {
        return true
      }
      
    },
    async getLessonGroupTips() {
      let params = {
        courseGroupId: this.courseId
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      
      this.orderId = response.data.orderId
    },
    confirm(type = 0) {
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$store.dispatch('setOrderInfo',{
            courseGroupId: this.detail.id,
            courseGroupName: this.detail.courseGroupName,
            jobListId: this.detail.jobListId,
            loCourseInfo: this.detail,
            classInfo: this.classList,
            throughPay: this.throughPay,
          })
          this.$nextTick(() => {
            this.toSingup(this.detail.interviewFlag)
          })
        } else {
          this.$store.dispatch('toggleDialog', true)
        }
      } else {
        this.$router.push({path: `/course/signup/review?orderId=${this.orderId}`})
      }
    },

    getClass(item){
      this.showClassList = true
    },

    getClassList(item){
      // console.log(item)
      this.classList = item
    },

    infoOnClose(item) {
      // console.log(item)
      this.showTeacherDetail = item
      
    },
    classOnClose(item){
      this.showClassList = item
    }
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.detail)
    }
  }
}
</script>

<style lang="less" scoped>
.course-detail{
  padding-bottom: 1rem;  
}
.course-detail .banner-box{
  width:100%;
  height:4.25rem;

}
.course-detail .banner-box img{
  width:100%;
  height:4.25rem;
}
.detail-flex{
  display: flex;
  justify-content: space-between;  
  margin-top:0.2rem;
}
.detail-flex .class-intro{
  width: 7.5rem;
  background: #FFFFFF;
  overflow: hidden;
}
// .detail-flex .class-right{
//   width: 320px;
// }
.detail{
  background:#F8F8F8;
}
.sign-box{
  // width:94%;
  height:1.2rem;
  display: flex;
  position:fixed;
  bottom:0;
  left: 0;
  right: 0;
  padding:0 3%;
  border-top:1px solid #F6F6F6;
  background:#fff;

}
.sign-box .weixin{
  display: flex;
  width:22%;
}
.sign-box .weixin img{
  width:0.49rem;
  height:0.4rem;
  margin:0.35rem 0;
}
.sign-box .weixin span{
  font-size: 14px;
  color:#333;
  line-height: 1.2rem;
  margin:0 0.1rem;
}
.sign-box .sign-btn{
  // width: 78%;
  width: 100%;
  height:0.8rem;
  line-height: 0.8rem;
  margin:0.2rem 3%;
  background: linear-gradient(90deg, #F15F32, #F13232);
  border-radius: 10px;
  font-size: 14px;
  color:#fff;
  text-align: center;
}
.sign-box .buy-btn-gray{
  background: #dbdbdb;
}
.wrapper{
  width:100%;
  height:100%;
  position: relative;
}

</style>