import { render, staticRenderFns } from "./BuyConsultative.vue?vue&type=template&id=37ca7874&scoped=true"
import script from "./BuyConsultative.vue?vue&type=script&lang=js"
export * from "./BuyConsultative.vue?vue&type=script&lang=js"
import style0 from "./BuyConsultative.vue?vue&type=style&index=0&id=37ca7874&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ca7874",
  null
  
)

export default component.exports