var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course"},[_vm._l((_vm.detail.chapterWebVos),function(groupitem,groupindex){return _c('div',{key:groupindex},[(_vm.detail.hasChapter)?_c('div',{staticClass:"course-group",on:{"click":function($event){return _vm.toggleClass(groupindex)}}},[_c('span',{staticClass:"cg-title"},[_vm._v(_vm._s(groupitem.chapterName))]),_c('em',{staticClass:"arrow",class:{'turnArrow' : _vm.listType[groupindex]}})]):_vm._e(),(_vm.detail.hasChapter)?[(_vm.listType[groupindex])?_c('div',_vm._l((groupitem.courseWebVos),function(classitem,classindex){return _c('div',{key:classindex,staticClass:"course-item",class:{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5},on:{"click":function($event){return _vm.toPlay(classitem)}}},[_c('div',{staticClass:"ci-left"},[_c('div',{staticClass:"ci-title"},[_c('div',{staticClass:"play-icon"}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(classitem.courseName))])]),_c('div',{staticClass:"time"},[_vm._v("主讲："+_vm._s(classitem.teacherName)+" "),_c('span',[_vm._v(_vm._s(classitem.classTime))])])]),_c('div',{staticClass:"ci-right"},[_c('span',{class:{
              'blue': classitem.liveStatus === 5,
              'green': classitem.liveStatus === 3,
              'red': classitem.liveStatus === 2,
              }},[_vm._v(_vm._s(_vm._f("liveStatusFilter")(classitem.liveStatus)))])])])}),0):_vm._e()]:_vm._e(),(!_vm.detail.hasChapter)?[_c('div',_vm._l((groupitem.courseWebVos),function(classitem,classindex){return _c('div',{key:classindex,staticClass:"course-item",class:{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5},on:{"click":function($event){return _vm.toPlay(classitem)}}},[_c('div',{staticClass:"ci-left"},[_c('div',{staticClass:"ci-title"},[_c('div',{staticClass:"play-icon"}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(classitem.courseName))])]),_c('div',{staticClass:"time"},[_vm._v("主讲："+_vm._s(classitem.teacherName)+" "),_c('span',[_vm._v(_vm._s(classitem.classTime))])])]),_c('div',{staticClass:"ci-right"},[_c('span',{class:{
              'blue': classitem.liveStatus === 5,
              'green': classitem.liveStatus === 3,
              'red': classitem.liveStatus === 2,
              }},[_vm._v(_vm._s(_vm._f("liveStatusFilter")(classitem.liveStatus)))])])])}),0)]:_vm._e()],2)}),(!_vm.detail.chapterWebVos)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }